
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import {
  computed, defineComponent, onBeforeUnmount, onMounted, PropType, Ref, ref, SetupContext,
} from 'vue';
import Tooltip from 'primevue/tooltip';
import { asInt } from '@/helpers';
import {
  ActionsDialogAddOperatorType,
  ActionsDialogBranchClosureType,
  ActionsDialogBranchMergeType,
  ActionsDialogRemoveOperator,
  AutomatedScenarioAnalytics,
  AutomatedScenarioState as state,
  BoxDialogType,
  compatibilityOperatorsCheck,
  isRootOfTree,
  OperatorInput,
  OperatorOutput,
  setAnalyticsDataToOperator,
  getFilterSegmentById, checkIfFilterLostLinkedBox,
} from '@/composables/AutomatedScenarios';
import {
  checkIfSendingChannelBoxLostConfig,
  sendingChannelBoxes,
} from '@/composables/automated-scenarios/AutomatedScenariosSendingChannel';
import { PrimvueMenuModel, TypeCampaignEnum } from '@/types';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import { getComponentMetadataById } from '@/components/automated-scenarios/metadata';
import { GlobalBulkSettingsData } from '@/types/automated-scenarios';
import { formatNumbersInString } from '@/helpers/Number';
import { TemplateTypeEnum } from '@/composables/shop/Templates';
import {
  hasAccessToFeatures,
} from '@/composables/User';
import { PlanName } from '@/types/enums';

export default defineComponent({
  name: 'OperatorDisplayBox',

  directives: {
    tooltip: Tooltip,
  },

  components: {
    Button,
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  props: {
    operatorId: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    hasConfiguration: {
      type: Boolean,
      required: true,
    },

    kind: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    grayscaled: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },

    input: {
      type: Object as PropType<OperatorInput>,
      required: false,
      default: () => Object,
    },

    outputs: {
      type: Array as PropType<OperatorOutput[]>,
      required: false,
      default: () => [],
    },

    analyticsMode: {
      type: Object as PropType<AutomatedScenarioAnalytics>,
      required: true,
    },

    visualisationMode: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: [
    'merge-connector',
    'input-clicked',
    'output-clicked',
    'exchange-clicked',
    'action-add-operator-clicked',
    'action-branch-merge-clicked',
    'action-branch-closure-clicked',
    'action-remove-operator-clicked',
    'edit-operator',
    'replace-operator',
    'highlighted',
    'unhighlighted',
    'send-test-message',
  ],

  setup(props: { operatorId: string; icon: string; hasConfiguration: boolean; kind: string; disabled: boolean;
    grayscaled: boolean; selectable: boolean; input: OperatorInput; outputs: OperatorOutput[];
    analyticsMode: AutomatedScenarioAnalytics; visualisationMode: boolean; id: string;}, context: SetupContext) {
    const { t, te } = useI18n();
    const targetBulkCalculations: any = ref(null);
    const datas = computed(() => state.scenario.getOperatorData<any>(props.operatorId));

    const hasPersistence = computed(() => Object.keys(datas.value.persistanceList ?? {}).length > 0);
    const iconClasses = `far ${props.icon}`;
    const containerClasses = computed(() => ({
      'display-box-container': true,
      'display-analytics-mode': props.analyticsMode.show,
      'display-box-declencheur': props.kind === 'declencheur',
      'display-box-filtre': props.kind === 'filtre',
      'display-box-action': props.kind === 'action',
      'display-box-disabled': props.disabled,
      'display-box-grayscaled': props.grayscaled,
      'display-box-selectable': props.selectable,
    }));
    const closePanelActions = ref(false);

    const analyticsData = computed(() => {
      const data = state.operators.filter((o) => o.operatorId === props.operatorId)[0];
      return data.analyticsData;
    });

    const exchanges = computed(() => props.outputs?.length - 1);
    const sendingChannelBoxLostConfig = ref(false);
    const isOperatorValid = ref(true);
    const hasNoContacts = ref(false);
    const queuedPendingImportLists = ref(0);
    const componentMetadata = getComponentMetadataById(props.id);

    const hasWarning = computed(() => {
      const warningMessage = [];
      const incompatibleWithParents = !compatibilityOperatorsCheck(props.operatorId);

      if (incompatibleWithParents) {
        warningMessage.push(`<li>- ${t('automatedScenarios.tooltips.incompatibilityOperator')}</li>`);
      }

      if (!isOperatorValid.value) {
        warningMessage.push(`<li>- ${t('automatedScenarios.tooltips.operatorIncompleteConfiguration')}</li>`);
      }

      if (sendingChannelBoxLostConfig.value) {
        warningMessage.push(`<li>- ${t('automatedScenarios.tooltips.operatorLostVoucherConfiguration')}`);
      }

      if (hasNoContacts.value) {
        warningMessage.push(`<li>- ${t('automatedScenarios.tooltips.hasNoContacts')}</li>`);
      }

      if (queuedPendingImportLists.value) {
        const mainMessageKey = 'automatedScenarios.tooltips.queuedPendingListsImport.main';
        const importsMessageKey = `automatedScenarios.tooltips.queuedPendingListsImport.import${queuedPendingImportLists.value > 1 ? 's' : ''}`;
        warningMessage.push(`<li>- ${t(mainMessageKey, [queuedPendingImportLists.value, t(importsMessageKey)])}</li>`);
      }

      if (componentMetadata && !componentMetadata.Meta.availableInCampaign.includes(state.scenario.type)) {
        const message = componentMetadata.Meta.customTooltipWarningMessage ? componentMetadata.Meta.customTooltipWarningMessage : 'automatedScenarios.tooltips.elementNotAvailable';
        warningMessage.push(`<li>- ${t(message)}</li>`);
      }

      return warningMessage.length ? `<ul>${warningMessage.join('')}</ul>` : false;
    });

    const rootOfTree = computed(() => isRootOfTree(props.operatorId));

    const boxHover = ref(false);

    const boxDialog = computed(() => {
      if (state.selectedOperator.operatorId === props.operatorId
        && state.selectedOperator.dialog !== null) {
        return state.selectedOperator.dialog;
      }
      return null;
    });

    const onMasterConnectorBtnClick = (e: PointerEvent, type: string, connector: OperatorInput|OperatorOutput) => {
      e.preventDefault();
      if (connector.connectorCanBeMerged || connector.connectorSelectedForMerge) {
        context.emit('merge-connector', e, type, connector);
      }
    };

    const onActionAddOperatorBtnClick = (e: PointerEvent, type: ActionsDialogAddOperatorType) => {
      e.preventDefault();
      context.emit('action-add-operator-clicked', e, type);
    };

    const onActionBranchMergeBtnClick = (e: PointerEvent, type: ActionsDialogBranchMergeType) => {
      e.preventDefault();
      context.emit('action-branch-merge-clicked', e, type);
    };

    const onActionBranchClosureBtnClick = (e: PointerEvent, type: ActionsDialogBranchClosureType) => {
      e.preventDefault();
      context.emit('action-branch-closure-clicked', e, type);
    };

    const onActionRemoveOperatorBtnClick = (e: PointerEvent|null, type: ActionsDialogRemoveOperator) => {
      if (e !== null) {
        e.preventDefault();
      }
      context.emit('action-remove-operator-clicked', e, type);
    };

    const onInputBtnClick = (e: PointerEvent) => {
      e.preventDefault();
      context.emit('input-clicked', e);
    };

    const onOutputBtnClick = (e: PointerEvent, i: string | number) => {
      e.preventDefault();
      context.emit('output-clicked', e, asInt(i));
    };

    const onExchangeBtnClick = (e: PointerEvent, i: string | number) => {
      e.preventDefault();
      context.emit('exchange-clicked', e, asInt(i));
    };

    const onEditOperator = (e: any) => {
      const target = e.originalTarget ?? e.target;

      if (target && target.className.includes('edit-operator-zone')
        && !(props.disabled || props.grayscaled)) {
        e.preventDefault();
        context.emit('edit-operator');
      }
    };

    const onMouseEnter = () => {
      boxHover.value = true;
      context.emit('highlighted');
    };

    const onMouseLeave = () => {
      boxHover.value = false;
      context.emit('unhighlighted');
    };

    const getPosOutput = (operatorId: string|null, output: number): string => {
      let idOperator = props.operatorId;
      if (operatorId !== null) {
        idOperator = operatorId;
      }

      const operatorOutputs = Array.from(document.querySelectorAll(`#flowchart_operator_${idOperator}
            .flowchart-operator-outputs .flowchart-operator-connector-set`)) as HTMLElement[];

      if (typeof (operatorOutputs[output - 1]) !== 'undefined') {
        const outputConnectorElementStyle = window.getComputedStyle(operatorOutputs[output - 1], null);
        const flowchartOutputLeft = outputConnectorElementStyle.left.match(/\d+/g);
        const flowchartOutputLeftInt = flowchartOutputLeft ? parseInt(flowchartOutputLeft[0], 10) - 1 : 0;
        return `left: ${flowchartOutputLeftInt}px;`;
      }

      return 'left: 0px;';
    };

    const getLeftPosBoxDialog = (): string => {
      if (state.selectedOperator.operatorId && state.selectedOperator.outputSelected) {
        return getPosOutput(state.selectedOperator.operatorId, parseInt(state.selectedOperator.outputSelected, 10));
      }
      return 'left: 24px;';
    };

    const getPosExchange = (index: number) => {
      const operatorOutputs = Array.from(document.querySelectorAll(`#flowchart_operator_${props.operatorId}
            .flowchart-operator-outputs .flowchart-operator-connector-set`)) as HTMLElement[];

      const lengthBox = operatorOutputs.length > 2 ? 225 : 50;
      const space = lengthBox / (operatorOutputs.length - 1);
      if (typeof operatorOutputs[index - 1] !== 'undefined') {
        const outputConnectorElementStyle = window.getComputedStyle(operatorOutputs[index - 1], null);
        const flowchartOutputLeft = outputConnectorElementStyle.left;

        const left = parseFloat(flowchartOutputLeft) + space / 2;
        return `left: ${left}px;`;
      }
      return '';
    };

    const getPersistenceTooltip = () => `${t('automatedScenarios.tooltips.persistence.title')}
    <ul>${Object.entries(datas.value.persistanceList).map((current: any) => {
    const subTypes = current[1].map((entry: string) => (te(`automatedScenarios.tooltips.persistence.subType.${entry}`)
      ? `<li>* ${t(`automatedScenarios.tooltips.persistence.subType.${entry}`)}</li>`
      : ''))
      .filter((entry: string) => entry !== '');
    return `<li>- ${t(`automatedScenarios.tooltips.persistence.mainType.${current[0]}`)} <ul class="sub-type">${subTypes.join('')}</ul></li>`;
  }).join('')}</ul>`;

    const featurePermission = computed(() => hasAccessToFeatures(props.id));
    const minimumPlan = computed(() => {
      if (featurePermission.value
        && featurePermission.value.minimumPlan !== PlanName.STANDARD
        && state.scenario.type === TypeCampaignEnum.BULK
      ) {
        return featurePermission.value.minimumPlan;
      }
      return null;
    });

    const itemsActionOnOperator: Ref<PrimvueMenuModel[]> = ref([
      {
        label: t('campaigns.common.operatorActions.edit'),
        icon: 'fa-regular fa-pen-to-square',
        command: () => {
          context.emit('edit-operator');
          closePanelActions.value = true;
        },
        minimumPlan: minimumPlan.value,
      },
      {
        label: t('campaigns.common.operatorActions.replace'),
        icon: 'fa-regular fa-rotate',
        command: () => {
          context.emit('replace-operator');
          closePanelActions.value = true;
        },
      },
      {
        label: t('campaigns.common.operatorActions.sendTestMessage'),
        icon: 'far fa-fw fa-paper-plane',
        command: () => {
          const templateType = props.id === 'boxsendemail' ? TemplateTypeEnum.EMAIL : TemplateTypeEnum.SMS;
          context.emit('send-test-message', props.operatorId, templateType);
          closePanelActions.value = true;
        },
        hide: !['boxsendemail', 'boxsendsms'].includes(props.id),
      },
      {
        label: t('campaigns.common.operatorActions.remove'),
        icon: 'fa-regular fa-trash',
        command: () => {
          onActionRemoveOperatorBtnClick(null, ActionsDialogRemoveOperator.OPEN_DIALOG);
          closePanelActions.value = true;
        },
      },
    ]);

    const validateOperator = async () => {
      if (state.scenario.type === TypeCampaignEnum.BULK && !props.visualisationMode) {
        const data = state.scenario.getOperatorData<any>(props.operatorId);
        if (data) {
          const metadata = getComponentMetadataById(data.id);
          if (metadata && metadata.Validate) {
            const validation = await metadata.Validate(data);
            if (!validation.success) {
              isOperatorValid.value = false;
            } else {
              const {
                exclusion,
                inclusion,
                categories,
                products,
                manufacturers,
                urls,
              } = data;

              const checkFilterValidation = async (filterData: any, filterSegmentId: string) => {
                const filterSegment = await getFilterSegmentById(filterSegmentId);

                if (filterSegment && filterSegment.validate && filterData) {
                  const fields = filterSegment.fields.map((field) => field.id);
                  const dataToValidate: Record<string, any> = {};

                  // eslint-disable-next-line no-restricted-syntax
                  for (const field of fields) {
                    const fieldData = filterData[field];
                    dataToValidate[field] = Array.isArray(fieldData) && fieldData.length ? fieldData[0] : fieldData;
                  }

                  const filterSegmentValidation = await filterSegment.validate(dataToValidate);

                  return filterSegmentValidation.success;
                }

                return true;
              };

              const checkFilterOptions = async (filter: Record<string, any>) => {
                const keys = Object.keys(filter);
                if (keys.length) {
                  // eslint-disable-next-line no-restricted-syntax
                  for (const key of keys) {
                    const filterData = filter[key];

                    // eslint-disable-next-line no-await-in-loop
                    const filterValidation = await checkFilterValidation(filterData, key);

                    if (!filterValidation) {
                      return false;
                    }
                  }
                }
                return true;
              };

              if (isOperatorValid.value && exclusion) {
                isOperatorValid.value = await checkFilterOptions(exclusion);
              }
              if (isOperatorValid.value && inclusion) {
                isOperatorValid.value = await checkFilterOptions(inclusion);
              }

              if (isOperatorValid.value && categories && typeof categories === 'object' && !Array.isArray(categories)
              && Object.keys(categories).length) {
                isOperatorValid.value = await checkFilterValidation(categories, 'categories');
              }

              if (isOperatorValid.value && products && typeof products === 'object' && !Array.isArray(products)
                && Object.keys(products).length) {
                isOperatorValid.value = await checkFilterValidation(products, 'products');
              }
              if (isOperatorValid.value && manufacturers && typeof manufacturers === 'object' && !Array.isArray(manufacturers)
                && Object.keys(manufacturers).length) {
                isOperatorValid.value = await checkFilterValidation(manufacturers, 'manufacturers');
              }
              if (isOperatorValid.value && urls && typeof urls === 'object' && !Array.isArray(urls)
                && Object.keys(urls).length) {
                isOperatorValid.value = await checkFilterValidation(urls, 'urls');
              }
            }
          }
        }
      }
    };

    const intervalCalculationId = ref();
    const intervalIsActive = ref(true);

    const checkImportState = async () => {
      if (state.scenario.type === TypeCampaignEnum.BULK && !props.visualisationMode) {
        const metadata = getComponentMetadataById(datas.value.id ?? '');
        if (metadata && metadata.CheckImportState) {
          queuedPendingImportLists.value = await metadata.CheckImportState(state.scenario.data, datas.value);
        }
      }
    };

    const calculateTargetBulk = async () => {
      if (state.scenario.type === TypeCampaignEnum.BULK && !props.visualisationMode) {
        const metadata = getComponentMetadataById(datas.value.id ?? '');
        if (metadata && metadata.TargetBulkCalcul) {
          targetBulkCalculations.value = 'loading';
          await checkImportState();
          targetBulkCalculations.value = await metadata.TargetBulkCalcul(
            state.scenario.data,
            datas.value,
            (state.scenario.settingsData as GlobalBulkSettingsData).commercial_campaign ?? 1,
          );
          if (targetBulkCalculations.value === 0) {
            hasNoContacts.value = true;
          } else if (targetBulkCalculations.value > 0) {
            hasNoContacts.value = false;
          }
        }
        if (intervalIsActive.value) {
          intervalCalculationId.value = setTimeout(async () => {
            await calculateTargetBulk();
          }, 5000);
        }
      }
    };

    onMounted(async () => {
      sendingChannelBoxLostConfig.value = sendingChannelBoxes.includes(datas.value.id)
        ? await checkIfSendingChannelBoxLostConfig(props.operatorId) : false;

      // Si les données analytics de cet operator sont null et que le mode analytics est activé, on les récupère
      if (analyticsData.value === null && props.analyticsMode.show) {
        await setAnalyticsDataToOperator(props.operatorId);
      }

      await checkIfFilterLostLinkedBox(datas.value.id, props.operatorId);

      await calculateTargetBulk();

      // Real time operator validation
      await validateOperator();
    });

    onBeforeUnmount(() => {
      intervalIsActive.value = false;
      if (intervalCalculationId.value) {
        clearTimeout(intervalCalculationId.value);
      }
    });

    return {
      t,
      analyticsData,
      onMasterConnectorBtnClick,
      iconClasses,
      containerClasses,
      rootOfTree,
      getPosOutput,
      getLeftPosBoxDialog,
      getPosExchange,
      onInputBtnClick,
      hasPersistence,
      exchanges,
      onOutputBtnClick,
      onExchangeBtnClick,
      onEditOperator,
      onMouseEnter,
      onMouseLeave,
      getPersistenceTooltip,
      hasWarning,
      boxDialog,
      onActionAddOperatorBtnClick,
      onActionBranchMergeBtnClick,
      onActionBranchClosureBtnClick,
      onActionRemoveOperatorBtnClick,
      ActionsDialogAddOperatorType,
      ActionsDialogBranchMergeType,
      ActionsDialogBranchClosureType,
      ActionsDialogRemoveOperator,
      BoxDialogType,
      itemsActionOnOperator,
      closePanelActions,
      targetBulkCalculations,
    };
  },

  methods: { formatNumbersInString },

});
